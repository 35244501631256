.contact{
    width: 100%;
    height: 790px;
    padding: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
}

.contact-title{
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: baseline;
    text-align: left;
    color: rgb(106, 65, 22);

}
.contact-second{
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}

.contact-info{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: rgb(238, 238, 238);
    padding: 20px ;
    color: rgb(106, 65, 22);
    

}

.contact-form{
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    flex-wrap: wrap;
    color: rgb(106, 65, 22);
    padding: 30px;

}
@media (max-width:1000px) {
    .contact{
        height: 1400px;
        padding: 100px 50px;
    }
    .contact-second{
        flex-direction: column;
    }
    .contact-info{
        width: 100%;
        height: 35%;
        margin-bottom: 70px;
        
    }
    .contact-form{
        width: 100%;
        height: 65%;
    }
}
@media (max-width:500px) {
    .contact{
        padding: 100px 20px;
    }
    .contact-form{
        padding: 0px;
    }
    .contact-info{
        height: auto;
        
    }
}
.contact-form form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    color: rgb(106, 65, 22);
    
}
.contact-form form button{
    width:45% !important;
    height: 40px !important;
    background-color: rgb(106, 65, 22);
    border: 1px solid black;
    color: white !important;
    
}
.contact-form form button:hover{
    background-color:rgb(126, 84, 38);
    color: white;
}
input:focus,
textarea:focus {
  outline: none; /* Varsayılan vurguyu kaldır */
  border: none; /* İstediğiniz bir sınıra geri dönüş yapabilirsiniz */
}
.contact-form form input{
    width: 49%;
    height: 15%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid black;
    color: rgb(106, 65, 22);

}
.contact-form form textarea{
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid black;
    color: rgb(106, 65, 22);
}
.contact-form form button{
    width: 50%;
    height: 40px;
    margin-top: 40px;
    color: rgb(106, 65, 22);
}
.contact-form .dataSucceed{
    width: auto;
    height: auto;
    padding: 7px 15px;
    background-color: rgb(24, 172, 24);
    color: white;
}
.contact-form .dataFail{
    width: auto;
    height: auto;
    padding: 7px 15px;
    background-color: rgb(217, 28, 28);
    color: white;
}

@media (max-width:500px) {
    .contact-title h2{
        font-size: 1.5rem;
}
.contact-title h6{
    font-size: 0.9rem;
}
}