.about{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 700px;
    min-height: 600px;
    padding: 50px 100px;
    
    
}
@media (min-width:1700px) {
    .about{
        padding: 0px 200px;
    }
}
@media (max-width:1024px) {
    .about{
        padding: 10px 50px;
    }
}
@media (max-width:1000px) {
    .about{
        flex-direction: column;
        height: 960px ;
        padding:  50px;
    }
}
@media (max-width:550px) {
    .about{
        padding:50px 0px;
        height: auto;
    }
}
.about-img{
    float: left;
    width: 40%;
    height: 80%;
    border-radius: 10px;
   
}
@media (max-width:1000px) {
    .about-img{
        width: 90%;
        height: 40%;
    }
}
.about-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    
}
.about-content{
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    width: 60%;
    height: 100%;
    padding: 0px 30px;
    color: rgb(106, 65, 22);
}
@media (max-width:1000px) {
    .about-content{
        width: 100%;
    }
}
@media (max-width:550px) {
    .about-content{
        padding:0px 20px;
    }
    .about-content h2{
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 1.2rem;
        font-weight: 600;
    }
    .about-content h6{
        font-size: 0.9rem;
        line-height: 30px;
    }
}

@media (max-width:350px){
    .about-content h2{
        font-size: 1rem;
        font-weight: 600;
    }
}
@media (max-width:311px){
    .about-content h2{
        font-size: 0.9rem;
        font-weight: 600;
    }
}