
.div{
    width: 100%;
    margin: 0px;
    padding: 0%;
    display: flex;
    flex-direction: column;
    
}

.div .banner{
    width: 100%;
    height: 40px;
    background-color:rgb(106, 65, 22) ;
    padding: 0px 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (max-width:850px) {
    .div .banner{
        position: absolute;
        top: 0px; 
        padding: 0px 0px;
    }
   
}

.div .banner a{
    text-decoration: none;
    color: white;
    font-size: 0.7rem;
    
}

.div .banner .icoo{
    font-size: 1.7rem !important;
    margin-right: 10px;
}
@media (max-width:850px) {
    .div .banner a{
        font-size: 0.5rem;
        padding: 0px 10px;
        
    }
    .div .banner .icoo{
        font-size: 1rem !important;
        margin-right: 5px;
    }
   
}

.scrolling-text-container {
    overflow: hidden;
    white-space: nowrap;
    
  }
  @media (max-width:850px) {
    .scrolling-text-container {
        display: none;
      }
}
  .scrolling-text {
    display: inline-block;
    animation: scrollText 15s linear infinite;
    color: white;
    font-size: 0.8rem;
  }
  
 
  
  @keyframes scrollText {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

.div .title{
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.7);
}
@media (max-width:850px) {
    .div .title{
        margin-top: 80px;
    }
}
.nav{
    /* position: relative; */
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    top: 0px;
    right: 0px !important;
    left: 0px;
    z-index: 10000000000000;
    /* transition: 1s; */
    background: rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgb(0, 0, 0,0.3);
    padding: 0px 100px;
    
}
.changeLanguage{
    background-color: transparent !important;
}
.nav.active{
    background: rgba(0, 0, 0, 0.6);
    margin-top: 0px;
    position: fixed;
}
@media (min-width:1700px) {
    .nav.active{
        padding: 0px 200px;
    }
}
.nav.navFixed{
    display: none;
   
}

.nav .social{
    
    width: auto;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav .social .ico{
    margin-right: 15px;
    padding: 3px;
    font-size: 2rem;
    border: 1px solid rgb(51, 51, 59);
    border-radius: 50%;
    background:rgb(51, 51, 59) ;
    color: white;
    cursor: pointer;
    transition: 0.2s;
}
.nav .social .ico:hover{
    background:white;
    color: rgb(51, 51, 59) ;
}
nav ul{
    line-height: 80px; 
    margin-right: 80px;
    display: flex;
    flex-direction: row;
    width: auto;
    height: 80px;
    align-items: center;
   
    
    
}
nav ul li a{
    text-decoration: none;
}
.nav .dropdown{
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    
}

.logo{
    line-height: 0px;
    display: inline-block; 
    /* width: 150px;
    height: 60px; */
    color: black;
    margin-left: 80px;
}
.logo-h1{
    color: #11c707;
    font-family: cursive;
    margin-left: 80px;
    line-height: 80px; 
}
.menu{
    list-style: none;
    display: inline-block;
    margin-left:15px;
    text-decoration: none;
    line-height: 50px;
    z-index: 100;
    
}
.menu .prod{
    
}
.menu-a1{
    text-decoration: none;
    border-radius: 10px;
    padding: 6px 15px;
    color: black;
    border: none;
    background-color: transparent !important;
    
}
.menu-a1:hover{
    text-decoration: none;
    color: white;
}
.menu-a2{
    text-decoration: none;
    border-radius: 10px;
    padding: 6px 15px;
    color: black;
    border: none;
    background-color: transparent !important;
    
}
.menu-a2:hover{
    text-decoration: none;
    color: black;
}

#menu-a-i{
    position: absolute;
    font-size: 2.5rem;
    margin: 20px 0 0 20px;
    float: left;
    display: none;
    z-index: 12000000000000000000;
    color: black !important;
}
#menu-a-i:hover{
    transform: scale(1.2);
    color: aqua;

}
#menu-a-i-2{
    position: absolute;
    font-size: 2.5rem;
    margin: 20px 0 0 20px;
    float: left;
    display: none;
    z-index: 1200000000;
    
    
}
.secondNav{
    width: 25%;
    height: 100%;
    position: fixed;
    background: rgb(0,0,0,0.8);
    z-index: 1100;
    display: none;
    left: -350px;
    justify-content: center !important;
    align-items: baseline;
    flex-direction: column;
    transition: 0.6s;
    
  
    
    padding: 0px !important;

}
.secondNav ul{
    margin-bottom: 70px;
    padding-left: 20px;
  
    
}
.secondNav ul li {
    list-style: none;
    margin-bottom: 40px;
    display: flex;
    justify-content: baseline;
    align-items: baseline;
    color: white;
    transition: 0.6;
    
    
}

.secondNav ul li a{
    text-decoration: none;
    color: white;
}




@media (max-width:1180px){
    .secondNav{
        display: flex;
        left: -550px;
        width: 30%;
        
    }
    
}



@media (max-width:850px){
    .nav{
        height: 50px;
        margin-top: 40px;
        position: fixed;
        display: flex;
        z-index: 100;
        background: rgba(255, 255, 255);
        padding-top: 0px;
        justify-content: space-between;
        align-items: center;
        
    }
    .social .ico {
        display: none;
    }
    .secondNav{   
        display: flex;
        left: -550px;
        width: 38%;
    }
    #menu-a-i{
        position: fixed;
        font-size: 1.6rem;
        margin: 50px 0 0 10px;
        float: left ;
        display: flex;
    }
    #menu-a-i-2{
        position: fixed;
        font-size: 1.6rem;
        margin: 15px 0 0 10px;
        float: left;
        display: none;
        z-index: 500000;
        color: black;
        opacity: 1;
    }
    .logo{
        width: 100px;
        height: 35px;
         margin-right: 60px;
        /*margin-top: 0px; */
    }
    
    nav ul{
        display: none;

        
    }
    
}
@media (max-width:900px){
    .nav{
       
        padding: 0px 10px!important;
        
    }
}

@media (max-width:500px){
    .nav{
        height: 50px;
        padding: 0px 10px!important;
        
    }
    
    .secondNav{
        display: flex;
        left: -450px;
        width: 55%;
    }
    #menu-a-i{
        position: fixed;
        font-size: 1.6rem;
        margin: 50px 0 0 10px;
        float: left ;
        display: flex;
        color: rgb(0, 0, 0) !important;
    }
    #menu-a-i-2{
        position: fixed;
        font-size: 1.6rem;
        margin: 15px 0 0 10px;
        float: left;
        display: none;
        z-index: 50000000000;
        color: black;
        opacity: 1;
    }
    .logo{
        width: 90px;
        height: 30px;
         margin-right: 60px;
        /*margin-top: 0px; */
    }
}
@media (max-width:400px){
    .nav{
        height: 50px;
        
    }
    .secondNav{
        display: flex;
        left: -350px;
        width: 90%;
    }
    .secondNav ul li {
        list-style: none;

        margin-bottom: 40px;


        color: white;

        transition: 0.6;
        
    }
    #menu-a-i{
        position: fixed;
        font-size: 1.6rem;
        margin: 50px 0 0 10px;
        float: left ;
        color: white;
        display: flex;
    }
    #menu-a-i-2{
        position: fixed;
        font-size: 1.6rem;
        margin: 15px 0 0 10px;
        float: left;
        display: none;
        z-index: 500000;
        color: white;
        opacity: 1;
    }
    .logo{
        width: 90px;
        height: 30px;
         margin-right: 60px;
        /*margin-top: 0px; */
    }

    
}


.navbar-icons{
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding-top: 10px;
   
  
}


.navbar-icons .icon{
    font-size: 2.2rem !important;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid white;
    color: white;
    transition: 0.4s;

}
@media (max-width:850px){
    .navbar-icons .icon{
        margin-right: 10px;
    }
}
@media (max-width:400px){
    .navbar-icons .icon{
        margin-right: 4px;
        font-size: 1.7rem !important;
    }
}

.navbar-icons .icon:hover{
    background-color: white;
    color: black;

}