.containerr{
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1) ;
    width: 100%;
    padding: 0px 100px;
}
@media (min-width:1700px) {
    .containerr{
        padding: 0px 200px;
    }
}
@media (max-width:850px) {
    .containerr{
       padding: 0px 50px; 
    }
    
}
@media (max-width:550px) {
    .containerr{
        padding: 0px 30px; 
     }
}

.link{
    width: auto !important;
    text-align: left !important;
    justify-content: baseline;

}
@media (max-width:550px) {
    .link{
        padding-right: 150px;
        margin-bottom: 20px;

    }
}
.link h5 {
    
}
.link ul {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    text-align: left !important;
    justify-content: baseline !important;
    
    padding: 0px !important;
    width: auto !important;
}
.link ul li {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: baseline !important;
    list-style: none;
    text-align: left;
    
    width: auto !important;
}
.link ul li a{
    text-decoration: none;
    text-align: left;
    width: auto !important;
}

.footerCon{
    width: auto;
}

.footerContent{
    width: auto !important;
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}
.icc{
    font-size: 2rem;
    margin-right: 10px;
}

.wp{
    position: fixed;
    bottom: 110px;
    right: 30px;
    width: 80px;
    height: 80px;
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    padding: 5px;
    background-color: #51f23c;
    color: white;
    border: 4px solid rgb(142, 255, 93);
    transition: 0.9s;
    cursor: pointer;
    z-index: 1000;
}
.wp:hover{
    background-color:white;
}
.wp .wpIco{
    margin-bottom: 15px;
}

.tel{
    position: fixed;
    bottom: 210px;
    right: 30px;
    width: 80px;
    height: 80px;
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    padding: 5px;
    background-color: #1379d1;
    border: 4px solid #4091d8;
    transition: 0.9s;
    cursor: pointer;
    z-index: 1000;
}
.tel:hover{
    background-color:white;
}
.tel .telIco{
    margin-bottom: 9px;
    margin-right: 6px;
    color: white;
}
.tel:hover .telIco{
    color: #1379d1;
    
}


.wp a{
    text-decoration: none;
    color: white;
    margin-top: 3px;
}
.wp a:hover{
    color: #51f23c;
}



@media (max-width:768px){
    .wp{
        position: fixed;
        bottom: 80px;
        right: 20px;
        width: 75px;
        height: 75px;
        font-size: 2.9rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 0px;
        background-color: #51f23c;
    color: white;
    border: 4px solid rgb(142, 255, 93);
    }
    .tel{
        position: fixed;
        bottom: 170px;
        right: 20px;
        width: 75px;
        height: 75px;
        font-size: 2.9rem;
        
    }
    .footerLogo{
        margin-bottom: 50px;
    }
    

}
@media (max-width:600px){
    .footerLogo{
       width: 100%;
    }
}

@media (max-width:380px){
    .wp{
        position: fixed;
        bottom: 80px;
        right: 20px;
        width: 55px;
        height: 55px;
        font-size: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 0px;
        background-color: #51f23c;
    color: white;
    border: 4px solid rgb(142, 255, 93);
    }
    .tel{
        position: fixed;
        bottom: 150px;
        right: 20px;
        width: 55px;
        height: 55px;
        font-size: 1.7rem;
        
    }
    .wp a{
        margin-top: 8px;
    }
   .tel .telIco{
    margin-top: 7px;
   }
    

 
}
