.content-about{
    width: 100%;
    height: 700px;
    
}
.content-about img{
    width: 100%;
    height: 40%;
    object-fit: cover;
}
.description-about{
    width: 100%;
    height: auto;
    padding: 30px 100px;
}
@media (max-width:768px) {
    .content-about{
        height: 900px;
        
    }
    .description-about{
        padding:  50px;
    }
}
@media (max-width:568px) {
    .content-about{
        height: auto;
        
    }
    .description-about{
        padding: 20px 15px;
    }
    @media (max-width:500px) {
        .description-about h2{
            font-size: 1.5rem;
    }
    .description-about h6{
        font-size: 0.9rem;
    }
    }
    
}