*{
  font-family:monospace;

}



h6{
  font-size:1.2rem;
 
}