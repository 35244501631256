.home{
    margin-top: 10px;
}

@media (max-width:950px) {
    .home .carousel {
        margin-bottom: 100px;
        
    }
    .home .carousel .carousel-inner .carousel-item img{
        width: 100%;
        height: 400px !important;
        
        
    }
}
@media (max-width:770px) {
    .home{
        margin-top: 10px;
    }
}

@media (max-width:500px) {
    .home{
        margin-top: 10px;
    }
    .home .carousel {
        margin-bottom: 100px;
        
    }
    .home .carousel .carousel-inner .carousel-item img{
        width: 100%;
        height: 300px !important;
       
        
    }
}

@media (max-width:400px) {
    .home{
        margin-top: 10px;
    }
    .home .carousel {
        margin-bottom: 50px;
        
    }
    .home .carousel .carousel-inner .carousel-item img{
        width: 100%;
        height: 250px !important;
       
        
    }
}
