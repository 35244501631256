.content{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 50px 100px;
}
@media (max-width:850px) {
    .content{
        
        padding: 50px 20px;
    }
}
.content .category{
    width: 100%;
   height: 300px;
   margin-bottom: 50px;
}
.content .category img{
    width: 100%;
   height: 100%;
   object-fit: cover;
   
}

.content .text{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.content .text .table{
    margin-left: 20px !important;
    width: 75%;
    
}
.content .text .listSecond{
    display: none;
}
summary::before {
    content: "\25C0";
    margin-top: 12px;
    margin-left: 730px;
    z-index: 100;
   position: absolute;
   
  }
  details[open] summary::before {
    content: "\25BC";
    margin-top: 13px;
    margin-left: 730px;
    z-index: 100;
   position: absolute;
  }
  @media (max-width:790px) {
    summary::before {
        content: "\25C0";
        margin-top: 12px;
        margin-left: 670px;
        z-index: 100;
       position: absolute;
       
      }
      details[open] summary::before {
        content: "\25BC";
        margin-top: 13px;
        margin-left: 670px;
        z-index: 100;
       position: absolute;
      }
  }
  @media (max-width:730px) {
    summary::before {
        content: "\25C0";
        margin-top: 12px;
        margin-left: 570px;
        z-index: 100;
       position: absolute;
       
      }
      details[open] summary::before {
        content: "\25BC";
        margin-top: 13px;
        margin-left: 570px;
        z-index: 100;
       position: absolute;
      }
  }
  @media (max-width:630px) {
    summary::before {
        content: "\25C0";
        margin-top: 12px;
        margin-left: 470px;
        z-index: 100;
       position: absolute;
       
      }
      details[open] summary::before {
        content: "\25BC";
        margin-top: 13px;
        margin-left: 470px;
        z-index: 100;
       position: absolute;
      }
  }
  @media (max-width:530px) {
    summary::before {
        content: "\25C0";
        margin-top: 12px;
        margin-left: 420px;
        z-index: 100;
       position: absolute;
       
      }
      details[open] summary::before {
        content: "\25BC";
        margin-top: 13px;
        margin-left: 420px;
        z-index: 100;
       position: absolute;
      }
  }
  @media (max-width:480px) {
    summary::before {
        content: "\25C0";
        margin-top: 12px;
        margin-left: 370px;
        z-index: 100;
       position: absolute;
       
      }
      details[open] summary::before {
        content: "\25BC";
        margin-top: 13px;
        margin-left: 370px;
        z-index: 100;
       position: absolute;
      }
  }
  @media (max-width:430px) {
    summary::before {
        content: "\25C0";
        margin-top: 12px;
        margin-left: 330px;
        z-index: 100;
       position: absolute;
       
      }
      details[open] summary::before {
        content: "\25BC";
        margin-top: 13px;
        margin-left: 330px;
        z-index: 100;
       position: absolute;
      }
  }
  @media (max-width:390px) {
    summary::before {
        content: "\25C0";
        margin-top: 12px;
        margin-left: 280px;
        z-index: 100;
       position: absolute;
       
      }
      details[open] summary::before {
        content: "\25BC";
        margin-top: 13px;
        margin-left: 280px;
        z-index: 100;
       position: absolute;
      }
  }
  @media (max-width:340px) {
    summary::before {
        content: "\25C0";
        margin-top: 12px;
        margin-left: 240px;
        z-index: 100;
       position: absolute;
       
      }
      details[open] summary::before {
        content: "\25BC";
        margin-top: 13px;
        margin-left: 240px;
        z-index: 100;
       position: absolute;
      }
  }

  
  details summary {
    list-style-type: ' ';
    
}

details[open] summary {
    list-style-type: ' ';
}
  
.content .text .list-group  {
    margin-right: 20px !important;
    width: 25%;
   
}

.content .text .list-group a{
    text-decoration: none;
    color: black;
    background-color: white;
}
@media (max-width:850px) {
    .content .text{
        flex-direction: column;
    }
    .content .text .table{
        margin-left: 0px !important;
        width: 100%;
        
    }
    .content .text .list-group {
        display: none;
    }
    .content .text .listSecond {
        display: flex;
        margin-right: 20px !important;
        width: 100%;
        margin-bottom: 40px;
       
    }
}