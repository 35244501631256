.product{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 850px;
    align-items: center;
    justify-content: space-between;
    padding: 50px 100px;
    background: rgb(247, 246, 246);
}
@media (min-width:1700px) {
    .product{
        padding: 100px 200px;
    }
}
@media (max-width:1024px) {
    .product{
        padding: 90px 50px;

    }
}

.product h2{
    color:  rgb(106, 65, 22);
}
@media (max-width:900px) {
    .product{
        height: auto !important;
        
    }
    .product h2{
        margin-bottom: 30px;
    }
}
@media (max-width:550px) {
    .product{
       padding: 90px 20px;
        
    }
    
}
.all-cards{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    
}

.card{
    width: 24%;
    height: 39%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px;
    border: 1px solid white;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    padding: 0px;
    transition: 0.7s;
    cursor: pointer;

}
@media (max-width:900px) {
    .card{
        width: 48%;
        margin-bottom: 27px;
    }
}
@media (max-width:550px) {
    .card{
        width: 96%;
        margin-bottom: 40px;
        
    }
}
.card:hover{
    border: 10px solid white;
}
.card a{
    width: 100%;
    height: 100%;
    text-decoration: none;
    color:  rgb(106, 65, 22);
}
.card img{
    width: 100%;
    height: 75%;
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
    object-position: right !important;
   
}
.card h6{
    width: 100%;
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
@media (max-width:550px) {
    .card h6{
        margin-top: 20px;
    }
}
.card:hover .card img{
    padding: -5px;
}